export const apiUrls = {
  token: {
    tokens: '/tokens',
    token: '/token',
    apr: '/tokens/apr'
  },
  v1: {
    stake: '/v1/stake',
    statistics: {
      sold: '/v1/statistics/sold',
      rate: '/v1/statistics/rate',
      buyback: '/v1/statistics/buyback',
      spent: '/v1/statistics/spent',
      pressure: '/v1/statistics/pressure',
      holder: '/v1/statistics/holder',
      dex: '/v1/statistics/dex'
    },
    activity: '/v1/activity'
  }
};
